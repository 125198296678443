input,
.content {
  display: none;
  background: #ffffff;
  line-height: 25px;
  padding: 5px 25px;
  color: rgb(0, 0, 0);
  font: normal 1em/150% Sans-Serif;
  width: 100%;
}

#one:checked ~ .one,
#two:checked ~ .two,
#three:checked ~ .three {
  display: block;
}

label {
  cursor: pointer;
  background: rgb(255, 255, 255);
  height: 35px;
  padding: 5px 10px;
  display: inline-block;
  text-align: center;
  color: rgb(0, 0, 0);
  font: normal 1em/150% Sans-Serif;
  margin-right: -3px;
  transition: background 0.25s linear;
}

label:hover,
input:checked + label {
  border-bottom: 1px solid #17E9E1;
  color: rgb(0, 0, 0);
}





.icon {
  margin: 0 9px 0 5px;
  font-size: 15px;
  color: gray;
}


.butn{
    font-size: 12px;
    background: transparent;
    border-color: transparent;
    font-family: inherit;
}




.button i{
  font-size: 18px;
  background-color: rgb(48 48 48);
  border-radius: 100px;
  padding: 10px;
  color: rgb(0, 0, 0);
}
.btn:hover {
  color: black;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}



.dpbutton{
  padding: 4px 5px 5px 5px;
  border-radius: 9px;
  border-color: #17E9e1;
}


/* ////////////////////////////////////////// */





.pyramid-loader {
  position: relative;
  width: 300px;
  height: 300px;
  display: block;
  transform-style: preserve-3d;
  transform: rotateX(-20deg);
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotateY(360deg);
  }
}

.pyramid-loader .wrapper .side {
  width: 70px;
  height: 70px;
/* you can choose any gradient or color you want */
  /* background: radial-gradient( #2F2585 10%, #F028FD 70%, #2BDEAC 120%); */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform-origin: center top;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.pyramid-loader .wrapper .side1 {
  transform: rotateZ(-30deg) rotateY(90deg);
  background-image: url('https://gateway.pinata.cloud/ipfs/QmbPh3vFcw8Xt2m1NG6RkXfozNzcjf1TtiCdxp2gbnuHVV');
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  /* background: conic-gradient( #2BDEAC, #F028FD, #D8CCE6, #2F2585); */
}

.pyramid-loader .wrapper .side2 {
  transform: rotateZ(30deg) rotateY(90deg);
  /* background: conic-gradient( #2F2585, #D8CCE6, #F028FD, #2BDEAC); */
  background-image: url('https://gateway.pinata.cloud/ipfs/QmbPh3vFcw8Xt2m1NG6RkXfozNzcjf1TtiCdxp2gbnuHVV');
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
}

.pyramid-loader .wrapper .side3 {
  transform: rotateX(30deg);
  /* background: conic-gradient( #2F2585, #D8CCE6, #F028FD, #2BDEAC); */
  background-image: url('https://gateway.pinata.cloud/ipfs/QmbPh3vFcw8Xt2m1NG6RkXfozNzcjf1TtiCdxp2gbnuHVV');
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
}

.pyramid-loader .wrapper .side4 {
  transform: rotateX(-30deg);
  /* background: conic-gradient( #2BDEAC, #F028FD, #D8CCE6, #2F2585); */
  background-image: url('https://gateway.pinata.cloud/ipfs/QmbPh3vFcw8Xt2m1NG6RkXfozNzcjf1TtiCdxp2gbnuHVV');
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
}

.pyramid-loader .wrapper .shadow {
  width: 60px;
  height: 60px;
  background: #8B5AD5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: rotateX(90deg) translateZ(-40px);
  filter: blur(12px);
}
/* /////////////////////////////////////////////////// */










.card {
  /* height: 254px;
  width: 190px; */
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 16px;
  /* box-shadow: 0 0 20px 8px #d0d0d0; */
  overflow: hidden;
}

 /*Image*/
.card-image {
  /* height: 100%;
  width: 100%; */
  position: absolute;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: #0a3394;
  background: linear-gradient(to top, #0a3394, #4286f4);
}

/*Description */
.card-description {
  display: flex;
  text-align: left;
  position: absolute;
  gap: 0em;
  flex-direction: column;
  background-color: #17e9e1;
  color: #000000;
  height: 70%;
  width: 100%;
  bottom: 0;
  border-radius: 16px;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 1rem;
}

/*Text*/
.text-title {
  text-align: left;
  font-size: 2rem;
  font-weight: 700;
}

.text-body {
  font-size: 1rem;
  line-height: 130%;
  width: 100%;
}


/* Hover states */
.card:hover .card-description {
  transform: translateY(100%);
}















/* /////////////////////////////////////////// */


.three-body {
  --uib-size: 35px;
  --uib-speed: 0.8s;
  --uib-color: #5D3FD3;
  position: relative;
  /* display: inline-block; */
  height: var(--uib-size);
  width: var(--uib-size);
  animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
 }
 
 .three-body__dot {
  position: absolute;
  height: 100%;
  width: 30%;
 }
 
 .three-body__dot:after {
  content: '';
  position: absolute;
  height: 0%;
  width: 100%;
  padding-bottom: 100%;
  background-color: var(--uib-color);
  border-radius: 50%;
 }
 
 .three-body__dot:nth-child(1) {
  bottom: 5%;
  left: 0;
  transform: rotate(60deg);
  transform-origin: 50% 85%;
 }
 
 .three-body__dot:nth-child(1)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite ease-in-out;
  animation-delay: calc(var(--uib-speed) * -0.3);
 }
 
 .three-body__dot:nth-child(2) {
  bottom: 5%;
  right: 0;
  transform: rotate(-60deg);
  transform-origin: 50% 85%;
 }
 
 .three-body__dot:nth-child(2)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite
     calc(var(--uib-speed) * -0.15) ease-in-out;
 }
 
 .three-body__dot:nth-child(3) {
  bottom: -5%;
  left: 0;
  transform: translateX(116.666%);
 }
 
 .three-body__dot:nth-child(3)::after {
  top: 0;
  left: 0;
  animation: wobble2 var(--uib-speed) infinite ease-in-out;
 }
 
 @keyframes spin78236 {
  0% {
   transform: rotate(0deg);
  }
 
  100% {
   transform: rotate(360deg);
  }
 }
 
 @keyframes wobble1 {
  0%,
   100% {
   transform: translateY(0%) scale(1);
   opacity: 1;
  }
 
  50% {
   transform: translateY(-66%) scale(0.65);
   opacity: 0.8;
  }
 }
 
 @keyframes wobble2 {
  0%,
   100% {
   transform: translateY(0%) scale(1);
   opacity: 1;
  }
 
  50% {
   transform: translateY(66%) scale(0.65);
   opacity: 0.8;
  }
 }
 





