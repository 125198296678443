.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.priBtn{
  background-color: #17E9E1;
  border-radius: 14px;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid;
  border-radius: 15px;
  border-color: #17E9E1;
  outline: 0;
}

.form-control:focus {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #17E9E1;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(253 201 13 / 25%);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid #17E9E1;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.backtoprev{
  display: flex;

  margin-left: 20px;

}


.input-group>.form-control, .input-group>.form-floating, .input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #17E9E1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.btn-outline-primary {
  --bs-btn-color: #17E9E1;
  --bs-btn-border-color: #17E9E1;
  --bs-btn-hover-color: rgb(0, 0, 0);
  --bs-btn-hover-bg: #17E9E1;
  --bs-btn-hover-border-color: #17E9E1;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: rgb(0, 0, 0);
  --bs-btn-active-bg: #17E9E1;
  --bs-btn-active-border-color: #17E9E1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #17E9E1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #17E9E1;
  --bs-gradient: none;
}

.btn-primary {
  --bs-btn-color: rgb(0, 0, 0);
  --bs-btn-bg: #17E9E1;
  --bs-btn-border-color: #17E9E1;
  --bs-btn-hover-color: rgb(0, 0, 0);
  --bs-btn-hover-bg: #17E9E1;
  --bs-btn-hover-border-color: #17E9E1;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: rgb(0, 0, 0);
  --bs-btn-active-bg: #17E9E1;
  --bs-btn-active-border-color: #17E9E1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgb(0, 0, 0)16, 16, 16);
  --bs-btn-disabled-bg: #17E9E1;
  --bs-btn-disabled-border-color: #17E9E1;
}

.card {
  --bs-card-border-width: 0px !important;
  background-color: rgb(255, 255, 255);
  border-color: #17E9E1;
  color: #000;
}

  .card2 {
    --bs-card-border-width: 1px !important;
    }


  .para {
    font-size: 10px;
  }
  .av {
    color: #17E9E1;
    text-decoration: auto !important;
  }
  .av:hover {
    color: gray;
    text-decoration: auto !important;
}

#outer {
    width: 450px;
    height: 240px;
    margin-top: -32px;
    margin-bottom: 10px;
    border-radius: 11px;
    overflow: hidden;
    position: relative;
}
.video {
  position: relative;
  left: 0;
  top: 0;
  opacity: 1;
}


.modal-content-dark {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  pointer-events: auto;
  background-color: #000000;
  background-clip: padding-box;
  border: 1px solid;
  border-radius: 15px;
  border-color: #17E9E1;
  outline: 0;
}

.swal2-container.swal2-center>.swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  background: #000000a8;
  justify-self: center;
}